<template>
  <section id="assignTask">
    <div class="listHeader">
      <h1 class="pl-3 homeHeader">Assign a Task</h1>
    </div>

    <div class="hr pl-2">
      <div class="flex justify-end space-x-5">
        <button class="edit-btn px-10 text-center mb-4" @click="$router.go(-1)">ASSIGN</button>
        <a class="font-extrabold text-ltgrey pt-2 pr-2 cursor-pointer" @click="$router.go(-1)"
          >CLOSE X</a
        >
      </div>
    </div>
    <div class="task-list relative w-full rounded p-4 grey mt-5 text-white grid grid-cols-2 gap-5">
      <div>
        <label class="text-14" for="taskname">TASK NAME</label>
        <input
          class="rounded"
          type="text"
          name="taskname"
          :value="`${zonelineLabels.name} Repair`"
        />
        <label class="text-14" for="description">DESCRIPTION</label>
        <textarea class="rounded" name="description" :value="tasks[0].description" />
      </div>
      <div class="">
        <label class="text-14" for="assignto">ASSIGN TO</label>
        <input class="rounded" type="text" name="assignto" :value="zonelineLabels.servicedBy" />
        <label class="text-14" for="property">PROPERTY</label>
        <input class="rounded" type="text" name="property" :value="propertyName" />
        <div class="inline-flex space-x-5">
          <div>
            <label class="text-14" for="building">BUILDING</label>
            <input class="rounded" type="text" name="assignto" :value="tasks[0].buildingName" />
          </div>
          <div>
            <label class="text-14" for="assignto">FLOOR</label>
            <input class="rounded" type="text" name="assignto" value="1" />
          </div>
          <div>
            <label class="text-14" for="assignto">{{ roomLabel }}</label>
            <input class="rounded" type="text" name="assignto" value="101" />
          </div>
        </div>
        <label class="text-14" for="target">TARGET COMPLETION DATE</label>
        <input class="rounded" type="text" name="target" value="XX/XX/XXXX" />
      </div>
      <!-- <h4 class="pl-2 text-xs font-bold">TASKS</h4>
      <p class="hr pl-2 pt-1 pb-2 text-white">
        <a class="float-left pr-2"><img src="@/assets/img/checkmark_circle.svg" alt=""/></a
        ><span class="line-through text-grey">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>
      </p>
      <p class="hr pl-2 pt-1 pb-2 text-white">
        <a class="float-left pr-2"><img src="@/assets/img/checkmark_emptycircle.svg" alt=""/></a>Lorem ipsum dolor sit
        amet, consectetur adipiscing elit.
      </p>
      <p class="hr pl-2 pt-1 pb-2 text-white">
        <a class="float-left pr-2"><img src="@/assets/img/checkmark_emptycircle.svg" alt=""/></a>Lorem ipsum dolor sit
        amet, consectetur adipiscing elit.
      </p>
      <p class="hr pl-2 pt-1 pb-2 text-white">
        <a class="float-left pr-2"><img src="@/assets/img/checkmark_emptycircle.svg" alt=""/></a>Lorem ipsum dolor sit
        amet, consectetur adipiscing elit.
      </p>
      <div class="mt-12"><router-link to="/assign-task" tag="button" class="edit-btn px-10 text-center">Create New Task</router-link></div> -->
    </div>
  </section>
</template>

<script>
  export default {
    components: {},
    data() {
      return {};
    },
    mounted() {
      window.scrollTo(0, 0);
    },
    computed: {
      propertyName: function() {
        return this.$store.state.properties[0].name;
      },
      roomLabel: function() {
        return this.$store.getters.propertyLabels('room');
      },
      zonelineLabels: function() {
        return this.$store.getters.applianceLabels('zoneline');
      },
      tasks: function() {
        return this.$store.getters.tasks();
      },
    },
    watch: {},
    methods: {},
  };
</script>
<style lang="scss" scope>
  @import '../styles/typovars';

  #assignTask {
    .hr {
      .text-grey {
        color: #979797;
        @media (hover: hover) {
          &:hover {
            color: #fff;
          }
        }
      }
    }
    label {
      color: $ltgrey;
      display: block;
      font-weight: 800;
      padding-bottom: 3px;
    }
    input,
    textarea {
      position: relative;
      display: block;
      padding: 15px;
      color: #fff;
      font-weight: 600;
      background: $dkgrey;
      margin-bottom: 15px;
      width: 100%;
    }
    textarea {
      resize: none;
      overflow: hidden;
      height: 250px;
    }
  }
  // @import "tailwindcss/base";
  // @import "tailwindcss/custom-forms";
</style>
